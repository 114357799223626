<template>
    <div class="layout-topbar">
        <div class="layout-topbar-logo" @click="$router.push({path: '/'})">
     <img style="max-height: 35px" src="https://getinov.com/wp-content/uploads/sites/2/2021/05/getinov_horizontal-white-small.png" alt="Getinov">
        </div>

        <a class="layout-menu-button" href="#" @click="onMenuButtonClick">
            <i class="pi pi-bars"></i>
        </a>

        <ul class="layout-topbar-menu" >
            <li :class="[{'active-topmenuitem': activeTopbarItem === 'settings'}]" class="layout-topbar-item"
                @click="openSettingVm">
                <a class="layout-topbar-icon first">
                    <i class="topbar-icon pi pi-cog"></i>
                </a>
                <transition name="layout-submenu-container">
                    <ul  class="fadeInDown" v-show="activeTopbarItem === 'settings'">
                        <li>
                           <i  class="fas fa-terminal" style="cursor:pointer" @click="openTerminal()"></i>
                        </li>
                        <li>
                        <i class="far fa-play-circle"  @click="start_vm()" style="cursor:pointer" ></i>
                        </li>
                        <li>
                       <i class="far fa-stop-circle"  @click="stop_vm()" style="cursor:pointer"></i>
                        </li>
                    </ul>
                </transition>
            </li>

            <li :class="[{'active-topmenuitem': activeTopbarItem === 'calendar'}]" class="layout-topbar-item"
                @click="$emit('topbar-item-click',{originalEvent:$event,item:'calendar'})">
                <a class="layout-topbar-icon first">
                    <i class="topbar-icon pi pi-calendar"></i>
                </a>
                <transition name="layout-submenu-container">
                    <ul class="fadeInDown" v-show="activeTopbarItem === 'calendar'">
                        <li>
                            <a href="#" class="layout-topbar-text">
                                SUN
                            </a>
                        </li>
                        <li>
                            <a href="#" class="layout-topbar-text">
                                MON
                            </a>
                        </li>
                        <li>
                            <a href="#" class="layout-topbar-text">
                                TUE
                            </a>
                        </li>
                       
                    </ul>
                </transition>
            </li>

            <li :class="['topbar-search',{'active-topmenuitem': activeTopbarItem === 'search'}]" class="layout-topbar-item"
                @click="$emit('topbar-item-click',{originalEvent:$event,item:'search'})">
                <a href="#" class="layout-topbar-icon first">
                    <i class="topbar-icon pi pi-search"></i>
                </a>
                <input type="text" placeholder="Type to search..." />
            </li>

            <li :class="[{'active-topmenuitem': activeTopbarItem === 'profile'}]" class="user-profile"
                @click="$emit('topbar-item-click',{originalEvent:$event,item:'profile'})">
                <a href="#">
                    <div class="user-profile-info profile-link">
                        <span class="user-profile-name">{{getUser.fullName}}</span>
                        <span class="user-profile-role">Admin</span>
                    </div>
                    <img class="logo" :src="getUser.picture"  />
                </a>
                <transition name="layout-submenu-container">
                    <ul class="fadeInDown" v-show="activeTopbarItem === 'profile'">
                        <li class="profile-detail">
                            <div class="user-profile-info">
                                <img class="logo" :src="getUser.picture" />
                                <div class="profile-info">
                                    <span class="user-profile-name">{{getUser.fullName}} </span>
                                    <span  class="user-profile-role">{{}}</span>
                                </div>
                                <a href="#" class="profile-detail-icon" @click="logout" >
                                    <i class="pi pi-sign-out"></i>
                                </a>
                            </div>
                        </li>
                        <li>
                            <a href="#" >
                                <i class="pi pi-user"></i>
                                <div class="menu-text">
                                    <span class="menu-title">Profile</span>
                                    <span class="menu-subtitle">Edit your profile</span>
                                </div>

                                <i class="right-icon pi pi-angle-right"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" >
                                <i class="pi pi-cog"></i>
                                <div class="menu-text">
                                    <span class="menu-title">Settings</span>
                                    <span class="menu-subtitle">Dashboard Settings</span>
                                </div>
                                <i class="right-icon pi pi-angle-right"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" >
                                <i class="pi pi-inbox"></i>
                                <div class="menu-text">
                                    <span class="menu-title">Messages</span>
                                    <span class="menu-subtitle">You have a <div class="blue">3 new</div> messages</span>
                                </div>
                                <i class="right-icon pi pi-angle-right"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" >
                                <i class="pi pi-globe"></i>
                                <div class="menu-text">
                                    <span class="menu-title">Notification</span>
                                    <span class="menu-subtitle">No new notification</span>
                                </div>
                                <i class="right-icon pi pi-angle-right"></i>
                            </a>
                        </li>
                    </ul>
                </transition>
            </li>
        </ul>
        <Dialog v-model:visible="dialogTerminal" :style="{width: '600px'}"  header='Mon terminal' :modal="true" class="p-fluid">
            <terminal></terminal>
             <ScrollTop target="parent" :threshold="100" class="custom-scrolltop" icon="pi pi-arrow-up" />
        </Dialog>

    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import axios from 'axios'
    import terminal from './components/terminal/vue/Terminal.vue'


    

    export default {
        components:{
            terminal
        },
        emits: ['topbar-item-click', 'menubutton-click', 'topbar-menubutton-click'],
        props: {
            topbarMenuActive: Boolean,
            horizontal: Boolean,
            activeTopbarItem: String,
        },
        data(){
            return {
                users:[],
                userName:"",
                currentPage:1,
                dialogTerminal: false,
                vm : this.$vm
                
            }
        },
        methods: {
            onMenuButtonClick(event) {
                this.$emit('menubutton-click', event);
            },
            openSettingVm(event){
                if(this.$vm !== undefined){
                     this.$emit('topbar-item-click',{originalEvent:event,item:'settings'})
                }
       
            },
            onTopbarMenuButtonClick(event) {
                this.$emit('topbar-menubutton-click', event);
            },
            logout(){
                this.$keycloak.logout()
            },
            openTerminal(){
                this.dialogTerminal = true
            },
            stop_vm() {
                    console.log('stop')
                    const opt = {
                    method: "GET",
                    url: "http://10.38.0.8:3000/instance/stop"
                    };
                    axios.request(opt);
                },
            start_vm() {
                    console.log('start')
                    const opt = {
                        method: "GET",
                        url: "http://10.38.0.8:3000/instance/start"
                    };
                    axios.request(opt);
                }
        },
        computed: {
         ...mapGetters(['getUser'])
        }
    }
</script>

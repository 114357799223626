import {
    AUTH_REQUEST,
    AUTH_ERROR,
    AUTH_SUCCESS,
    AUTH_LOGOUT
  } from "../actions/auth";
import { USER_REQUEST } from "../actions/user";

import AuthService from '../../service/AuthService'

const state = {
  token: localStorage.getItem("user-token") || "",
  status: "",
  hasLoadedOnce: false
};

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status
};

const actions = {
  [AUTH_REQUEST]: ({commit, dispatch}, user ) => {
    commit(AUTH_REQUEST);
    return AuthService.login(user).then(
      user => {
          commit(AUTH_SUCCESS, user);
          dispatch(USER_REQUEST);
          return Promise.resolve(user);
      },
      error => {
        commit(AUTH_ERROR, error);
        localStorage.removeItem("user-token")
        return Promise.reject(error);
      }
    )
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    AuthService.logout();
    commit(AUTH_LOGOUT);
  }
};

const mutations = {
  [AUTH_REQUEST]: state => {
    state.status = "loading";
  },
  [AUTH_SUCCESS]: (state, resp) => {
    state.status = "success";
    state.token = resp.token;
    state.hasLoadedOnce = true;
  },
  [AUTH_ERROR]: state => {
    state.status = "error";
    state.hasLoadedOnce = true;
  },
  [AUTH_LOGOUT]: state => {
    state.token = "";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
import createResource from '../api/resources'
import axios from 'axios';



export default {
    install(app) {
        const axiosResource = createResource(axios)

        const resources = {
            category: axiosResource('categories'),
            site: axiosResource('sites'),
            offers: axiosResource('offers'),
            companies: axiosResource('companies'),
            places: axiosResource('places'),
            contacts: axiosResource('contacts'),
            projects: axiosResource('projects'),
            users: axiosResource('users'),
            projectUserEntries: axiosResource('project_user_entries'),
            projectItems: axiosResource('project_items'),
            

        }
      
        app.config.globalProperties.$resources = resources;
    }
 }
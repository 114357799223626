<template>
	<AppSubmenu class="layout-menu clearfix" :items="menus" :layoutMode="layoutMode" :menuActive="active"
                :root="true" :parentMenuItemActive="true"/>
</template>

<script>
import AppSubmenu from './AppSubmenu';
//import ToolGroupService from "./service/ToolGroupService";
import { mapGetters } from 'vuex'

export default {
	props: {
		layoutMode: String,
		active: Boolean
    },
    data() {
        return {
            menus : [
				{label: 'Accueil', icon: 'pi pi-fw pi-home', to:'/'},
				{label: 'CRM', icon: 'fas fa-chart-pie', items: [
					{label: 'Offres', icon: 'far fa-newspaper', to: '/crm/offres'},	
					{label: 'Entreprises', icon: 'far fa-building', to: '/crm/entreprises'}	,
					{label: 'Contacts', icon: 'far fa-address-book', to: '/crm/contacts'}	
				]},
				{label: 'Projet', icon: 'far fa-folder', items: [
					{label: 'Liste des projets', icon: 'fas fa-list-ol', to: '/projects'},
				]},
				/*{label: 'Les machines', icon: 'pi pi-fw pi-cog', items: [
					{label: 'Toutes les machines', icon: 'pi pi-fw pi-list', visible: () => this.isAdmin(), to: '/tools'}	
				]},*/

				{
					label: 'Signature', icon: 'pi pi-fw pi-briefcase',
					items: [
						{label: 'Modifier sa signature', icon: 'pi pi-fw pi-pencil', to: '/signature'},
					]
				}
			]
        }
    },
	components: {
		'AppSubmenu': AppSubmenu
	},
	mounted() {
        /*ToolGroupService.list().then((groups) => {
			groups.data.forEach(item => {
				this.menus[2].items.push({
					label: item.name
				});
			});
        });*/
    
	},
	computed: {
		...mapGetters(['getUser'])
	},
	methods:{
		isAdmin() {
			return this.getUser.isAdmin;
		}  
	}  
}
</script>

<style scoped>

</style>

<template>
	<div class="layout-footer" >
        <div class="p-grid">
            <div class="p-col-12 p-md-6  p-lg-3">
                <span class="logo">Getinov</span>
            </div>
            <div class="p-col-12 p-md-6 p-lg-3">
                <span>LATEST TEMPLATES</span>
                <div class="p-grid templates">
                    <ul>
                        <li> <a href="https://primefaces.org/prestige-vue/" target="_blank" rel="noopener noreferrer">Prestige</a></li>
                        <li> <a href="https://primefaces.org/roma-vue/" target="_blank" rel="noopener noreferrer">Roma</a></li>
                        <li> <a href="https://primefaces.org/babylon-vue/" target="_blank" rel="noopener noreferrer">Babylon</a></li>
                        <li> <a href="https://primefaces.org/ultima-vue/" target="_blank" rel="noopener noreferrer">Ultima</a></li>
                        <li> <a href="https://primefaces.org/diamond-vue/" target="_blank" rel="noopener noreferrer">Diamond</a></li>

                    </ul>
                    <ul>
                        <li><span></span></li>
                    </ul>
                    <ul>
                        <li> <a href="https://primefaces.org/apollo-vue/" target="_blank" rel="noopener noreferrer">Apollo</a></li>
                        <li> <a href="https://primefaces.org/serenity-vue/" target="_blank" rel="noopener noreferrer">Serenity</a></li>
                        <li> <a href="https://primefaces.org/avalon-vue/" target="_blank" rel="noopener noreferrer">Avalon</a></li>
                        <li> <a href="https://primefaces.org/sapphire-vue/" target="_blank" rel="noopener noreferrer">Sapphire</a></li>
                    </ul>
                </div>
            </div>
            <div class="p-col-12 p-md-6 p-lg-3">
                <span>DEMOS</span>
                <div class="p-grid">
                    <ul class="links">
                        <li> <a href="https://primefaces.org/primevue/showcase/" target="_blank" rel="noopener noreferrer">PrimeVue</a></li>
                        <li> <a href="https://primefaces.org/primeng/showcase/" target="_blank" rel="noopener noreferrer">PrimeNG</a></li>
                        <li> <a href="https://primefaces.org/primereact/showcase/" target="_blank" rel="noopener noreferrer">PrimeReact</a></li>
                    </ul>
                </div>

            </div>
        </div>
	</div>
</template>

<script>

	export default {
		name: "AppFooter",
        data(){
            return{
               token: localStorage.getItem('vue-token'),
        
            }
        },

	}
</script>

<style scoped>

</style>

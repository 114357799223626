

export default $axios => resource  => ({
    list(page, filters) {
        page = page || 1; 
        filters = filters || {};   
        const query = {
            page: page,
            ...filters
        };
       
        return $axios.get(`/${resource}`, {params: query }).then (
            resp => {
                return {
                data: resp.data['hydra:member'],
                totalItems: resp.data['hydra:totalItems']
                }; 
            });
        
      
    },
    child(id, subresource) {
        return $axios.get(`/${resource}/${id}/${subresource}`).then (
            resp => {
                return {
                data: resp.data['hydra:member'],
                totalItems: resp.data['hydra:totalItems']
                }; 
            });
    },
    create(payload) {
        return $axios.post(`/${resource}`, payload).then(response => response.data)
    },
    get(id) {
        return $axios.get(`/${resource}/${id}`).then(response => response.data)
    },
    update(id, payload) {
        return $axios.put(`/${resource}/${id}`, payload).then(response => response.data)
    },
    delete(id) {
        return $axios.delete(`/${resource}/${id}`)
    }
})
import { USER_REQUEST, USER_ERROR, USER_SUCCESS } from "../actions/user";
import { AUTH_LOGOUT } from "../actions/auth";
import UserService from '../../service/UserService'

const state = { status: "", user: {} };

const getters = {
  getUser: state => state.user,
  isUserLoaded: state => !!state.user.id
};

const actions = {
  [USER_REQUEST]: ({ commit, dispatch }) => {
    commit(USER_REQUEST);
    UserService.get('me').then(
        data => {
          commit(USER_SUCCESS, data);
        },
        error => {
          commit(USER_ERROR, error);
          dispatch(AUTH_LOGOUT);
          } 
      )
    }
  };
  
const mutations = {
    [USER_REQUEST]: state => {
        state.status = "loading";
    },
    [USER_SUCCESS]: (state, resp) => {
        state.status = "success";
        state.user = resp;// {...state.user, resp};
    },
    [USER_ERROR]: state => {
        state.status = "error";
    },
    [AUTH_LOGOUT]: state => {
        state.user = {};
    }
};
  
export default {
    state,
    getters,
    actions,
    mutations
};
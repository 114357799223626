import { createStore } from 'vuex'
import { createLogger } from 'vuex'

import auth from './modules/auth' 
import user from './modules/user' 

export default createStore({
  plugins: process.env.NODE_ENV !== 'production' ? [createLogger()] : [], 
  modules: {
    auth,
    user
  }
})

import axios from 'axios';

class AuthService {
    login() {

        /*return axios
            .post(process.env.VUE_APP_KEYCLOAK_URL + '/realms/' + process.env.VUE_APP_KEYCLOAK_REALM + '/protocol/openid-connect/token', {
                email: user.email,
                password: user.password,
                client_id: process.VUE_APP_KEYCLOAK_CLIENT,
                grand_type: 'password'
            })
            .then(response => {
                if (response.data.token){
                    localStorage.setItem('user-token', response.data.token);
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
                }

                return response.data;
            });*/ 
    }

    logout() {
        localStorage.removeItem('user-token');
        delete axios.defaults.headers.common['Authorization'];
    }  
} 

export default new AuthService();
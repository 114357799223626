<template>
  <div id="xterm-container"></div>
</template>

<script>
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import { getCode } from "../api/ressource";

var curr_line = "";
var socket;
var queue = [];
var index;
const fit = new FitAddon();
const term = new Terminal({
  cursorBlink: "block",
  scrollback: 1000,
  tabStopWidth: 8,
  rows : 20
});
term.loadAddon(fit);
term.onKey((e) => {
  fit.fit();
  if (e.domEvent.ctrlKey == false) {
    switch (e.key) {
      case "\r":
        if (curr_line && curr_line != "clear") {
          socket.send(curr_line);
          queue.push(curr_line);
          term.write("\n \r");
          curr_line = "";
          index = queue.length;
        } else if (curr_line == "clear") {
          curr_line = "";
          term.clear();
        }
        break;
      case "\x7f":
        if (curr_line) {
          curr_line = curr_line.slice(0, curr_line.length - 1);
          term.write("\b \b");
        }
        break;
      case "\x1B[A":
        if (index - 1 != -1 && queue.length != 0) {
          curr_line = queue[index - 1];
          index -= 1;
          term.clear();
          term.write("\r\nType \x1B[1;3;31mhere\x1B[0m $ ");
          term.write(curr_line);
          term.write("\n \r");
        }
        break;
      case "\x1B[B":
        if (index + 1 < queue.length) {
          curr_line = queue[index - 1];
          index += 1;
          term.clear();
          term.write("\r\nType \x1B[1;3;31mhere\x1B[0m $ ");
          term.write(curr_line);
          term.write("\n \r");
        }
        break;
      default:
        curr_line += e.key;
        term.write(e.key);
        break;
    }
  }
}); 
export default {
  name: "terminal",
  data() {
    return {
      actived: false,
      command: null,
      stack: null,
      code: null,
    };
  },
  created() {
    this.onSubmit()
  },
  methods: {
    async onSubmit() {
      await getCode(this.$userToken).then((res) => {
        this.code = res.data;
      });

      if (this.actived == false && this.code) {
        socket = new WebSocket(
          "ws://10.38.0.8:3000/instance/ws?code=" + this.code
        );
        term.open(document.getElementById("xterm-container"));
        term.write("\x1B[1;3;31mGETINOV SHELL\x1B[0m $ ");
        this.actived = true;
        // document.getElementsByClassName("xterm-helper-textarea")[0].style.display = "none"
        document.getElementsByClassName("xterm-char-measure-element")[0].style.display = "none"
        
        // document.getElementById("server").remove();
        term.focus();
      }
      // socket.addEventListener("close", function () {
      //   alert("CONNECTION CLOSED");
      //   window.location.reload(true);
      // });
      // socket.addEventListener("error", function () {
      //   alert("CONNECTION ERROR");
      //   window.location.reload(true);
      // })
      socket.addEventListener("message", function (event) {
        if (event.data.includes("ERROR:")) {
          alert(event.data);
          window.location.reload(true);
        } else {
          if (
            event.data != "" &&
            event.data != "TERM environment variable not set.\n"
          ) {
            const final = event.data.split("\n");
            final.forEach((element) => {
              term.write(element + " ");
            });
            term.write("\r\nType \x1B[1;3;31mhere\x1B[0m $ ");
          } else {
            term.write("\rType \x1B[1;3;31mhere\x1B[0m $ ");
          }
        }
      });
    },
  },
};
</script>


import axios from 'axios';

 class UserService {
  
  list() {
    return axios.get('/users').then (resp => resp.data);
  }

  get(id) {
    return axios.get('/users/' + id).then (resp => resp.data);
  }
  
}

export default new UserService();

<template>
    <body class="login-body">
        <div class="mobile-banner">
            <img src="assets/layout/images/logo-mobile.svg" alt="prestige-layout"/>
        </div>
        <div class="login-panel">
            <div class="logo">
                <img src="assets/layout/images/logo.svg" alt="prestige-layout"/>
            </div>
        </div>
        <div class="login-card">
            <div class="top-border"></div>
            <div class="login-content">
                <h1>Connexion</h1>
                <p>Welcome, please use the form to sign-in.</p>
                <Message v-if="message" severity="error" :closable="false">{{message}} </Message>
                <form name="login" @submit.prevent="handleLogin(!v$.$invalid)">
                    <div class="username-container">
                        <div class="login-input p-field">
                            <InputText id="email" v-model="v$.user.email.$model" placeholder="Email"  :class="{'p-invalid':v$.user.email.$invalid && isSubmitted}"/>
                            <div v-if="(v$.user.email.$invalid && isSubmitted) || v$.user.email.$pending.$response" class="p-error">
                                <small v-if="v$.user.email.required.$invalid">Veuillez renseigner ce champ.</small>
                                <small v-if="v$.user.email.email.$invalid">Adresse Email incorrecte.</small>
                            </div>
                        </div>
                    </div>
                    <div class="password-container">
                        <div class="login-input">
                            <InputText id="password" type="password" v-model="v$.user.password.$model" placeholder="Mot de passe" :class="{'p-invalid':v$.user.password.$invalid && isSubmitted}"/>
                            <small v-if="(v$.user.password.$invalid && isSubmitted) || v$.user.password.$pending.$response" class="p-error">
                                Veuillez renseigner ce champ.
                            </small>	
                        </div>
                    </div>
                    <div class="forgetpassword-container">
                        <a href="#" class="forget-password">Mot de passe perdu ?</a>
                    </div>
                    <div>
                        <Button type="submit" :disabled="loading" style="text-align: center;">
                            <div style="width: 100%; text-align: center">
                                <span v-show="!loading">Connexion</span>
                                <i v-show="loading" class="pi pi-spin pi-spinner" style="fontSize: 2rem"></i>
                            </div>
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    </body>
</template>

<script>
import User from '../models/user';
import { AUTH_REQUEST } from "actions/auth";
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export default {
    name: 'Login',
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            user: new User('', ''),
            loading: false,
            isSubmitted: false,
            message: ''
        }
    },
    validations() {
        return {
            user: {
                email: {required, email},
                password: {required}
            } 
        } 
    },
    computed: {
        isAuthenticated() {
            return this.$store.getters.isAuthenticated;
        }
    },
    created() {
        if (this.isAuthenticated) {
            //this.$router.push('/');
        }
    },
    methods: {
        handleLogin(isValid) {
            this.isSubmitted = true;
            this.message = '';
            
            if (!isValid){
                return;
            } 

            this.loading = true;
            this.$store.dispatch(AUTH_REQUEST, this.user).then(
                () => {
                    this.$router.push('/');
                }, 
                error => {
                    this.loading = false;
                    this.message = (error.response && error.response.data && error.response.data.message)
                } 
            )
        } 
    }
}
</script>

<style scoped>

</style>
import axios from 'axios';

export async function getCode(token) {
    const options = {
        method: "GET",
        url: "http://10.38.0.8:3000/instance/startshell",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + token,
            "Access-Control-Allow-Headers": "Authorization",
        },
    };
    return await axios.request(options);
}